const React = require('react')
const styles = require('./profile.css')

function Container(props) {
  return <div className={styles.container}>{props.children}</div>
}

function Content(props) {
  return <div className={styles.contentColumn}>{props.children}</div>
}

function Sidebar(props) {
  return <div className={styles.sidebar}>{props.children}</div>
}

module.exports = {
  Container,
  Content,
  Sidebar,
}
