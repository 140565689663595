'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const connect = require('../connect')
const styles = require('./profile.css')
const Link = require('@npm/spiferack/link')

class OrgRow extends React.PureComponent {
  render() {
    const {orgName, tfaEnforced, key = `row-${orgName}`} = this.props

    const active = tfaEnforced === true

    return (
      <li className={styles.listItem} key={`${key}-avatar`}>
        <div className="flex-ns">
          <Link>
            <a href={`/org/${orgName}`} className={styles.orgLink}>
              {orgName}
            </a>
          </Link>
          {active && <div>2FA Required</div>}
        </div>
      </li>
    )
  }
}

OrgRow.propTypes = {
  orgName: PropTypes.string.isRequired,
  tfaEnforced: PropTypes.bool.isRequired,
}

module.exports = connect()(OrgRow)
