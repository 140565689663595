'use strict'
const PropTypes = require('prop-types')
const React = require('react')

const styles = require('../tabs/tabs.css')
const Tabs = require('../tabs/tabs')
const pluralize = require('../../utils/pluralize')
const BoxesIcon = require('../icons/boxes')
const GroupIcon = require('../icons/group')

function ProfileTabs(props) {
  const {packageCount, orgCount, active} = props

  const links = [
    {
      key: 'packages',
      label: (
        <span>
          <BoxesIcon />
          <span className={styles.number}>{packageCount.toLocaleString()}</span>
          {pluralize(packageCount)` Package${['s']}`}
        </span>
      ),
    },
    {
      key: 'orgs',
      label: (
        <span>
          <GroupIcon />
          <span className={styles.number}>{orgCount.toLocaleString()}</span>
          {pluralize(orgCount)` Organization${['s']}`}
        </span>
      ),
    },
  ].filter(Boolean)
  return <Tabs links={links} colors={['violet', 'green', 'yellow', 'red', 'teal']} active={active} />
}

ProfileTabs.propTypes = {
  active: PropTypes.string,
}

ProfileTabs.defaultProps = {
  active: 'packages',
}

module.exports = ProfileTabs
