const React = require('react')
const PropTypes = require('prop-types')
const forms = require('../../styles/forms.css')

function YourAccount(props) {
  const {userName} = props

  return (
    <div className="mt2">
      <a className={forms.buttonGradient} href={`/settings/${userName}/profile`}>
        Edit Profile
      </a>
    </div>
  )
}

YourAccount.propTypes = {
  userName: PropTypes.string.isRequired,
}

module.exports = YourAccount
