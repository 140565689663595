'use strict'

const React = require('react')
const {Helmet} = require('react-helmet')
const PropTypes = require('prop-types')
const styles = require('./profile.css')
const pluralize = require('../../utils/pluralize')
const PackageList = require('../package-list/package-list')
const Link = require('@npm/spiferack/link')
const forms = require('../../styles/forms.css')

function Packages(props) {
  const {packages, isCurrentUser, isTabPanel, isSubscribed, scope} = props
  const p = pluralize(packages.total)
  const tabPanelAttributes = {
    role: 'tabpanel',
    id: 'tabpanel-packages',
    'aria-expanded': true,
  }

  return (
    // renders the page with tabPanelAttributes only if there is a tab panel
    <div {...(isTabPanel ? tabPanelAttributes : {})}>
      <Helmet>
        <title>npm | Profile</title>
      </Helmet>
      <div className={styles.listHeader}>
        <h2 className={styles.listHeading}>
          <Link>
            <a id="packages" href="?activeTab=packages" className={styles.headingLink}>
              <span className={styles.packagesHeading}>{p`Package${['s']} `}</span>
              <span className={styles.packagesTotal}>{packages.total}</span>
            </a>
          </Link>
        </h2>
        <div className="flex-auto" />
        {isCurrentUser && !isSubscribed && (
          <Link>
            <a href={`/settings/${scope.parent.name}/billing/upgrade`} className={`${forms.buttonGradient} ws-normal`}>
              <strong>
                <span aria-hidden>+</span> Add Private Packages
              </strong>
            </a>
          </Link>
        )}
      </div>
      <PackageList packages={packages.objects} />
    </div>
  )
}

Packages.propTypes = {
  packages: PropTypes.shape({
    total: PropTypes.number.isRequired,
    objects: PropTypes.array.isRequired,
  }),
  isTabPanel: PropTypes.bool,
}

module.exports = Packages
