const React = require('react')
const PropTypes = require('prop-types')
const forms = require('../../styles/forms.css')

class NextButton extends React.PureComponent {
  render() {
    const {urls, loading} = this.props
    const text = loading ? 'loading' : 'show more packages'

    if (urls.next) {
      return (
        <a href={urls.next} className={forms.buttonGradientInline} onClick={ev => this.onClick(ev)}>
          {text}
        </a>
      )
    }

    return null
  }

  onClick(event) {
    event.preventDefault()
    event.target.blur()
    if (this.props.loading) return
    this.props.onClick()
  }
}

NextButton.defaultProps = {
  loading: false,
}

NextButton.propTypes = {
  urls: PropTypes.shape({
    next: PropTypes.string,
    prev: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

module.exports = NextButton
