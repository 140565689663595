const React = require('react')
const PropTypes = require('prop-types')
const styles = require('./profile.css')
function UserNote(props) {
  const {userName} = props

  return (
    <div className={styles.greyBox}>
      <p className="ma0 f6">
        Link your social accounts in the
        <a href={`/settings/${userName}/profile`}> Account Settings</a> to make them visible to others.
      </p>
    </div>
  )
}

UserNote.propTypes = {
  userName: PropTypes.string.isRequired,
}
module.exports = UserNote
